const projects = [
  {
    id: 1,
    name: 'IsBin',
    description: 'A simple library application written in Java with Springboot and Thymeleaf.',
    longDescription: '',  
    team: ['J.V.D'],
    link: 'https://isbin.qwict.com',
    github: 'https://github.com',
    image: 'QwictCorner.png',
    lastUpdated: '2023-09-10',
  },
  {
    id: 2,
    name: 'Todo App',
    description: 'Joris his first React app, a simple todo app that allows you to share todo-lists with other people.',
    longDescription: "My first React application is a versatile to-do application integrated with Auth0 for user authentication. Users can log in with their GitHub, Google, or email and password credentials. They can create to-do lists, share them with others, and effortlessly add and check off items. The application's frontend is built with React and features a sleek Material UI design, while the backend relies on Koa to create the API. Data is stored and managed in a MySQL database, and the application is seamlessly connected to Auth0 for secure user authentication.",
    team: ['J.V.D'],
    link: 'https://todo.qwitc.com',
    github: 'https://github.com',
    image: 'QwictHorizontal.png',
    lastUpdated: '2023-05-20',
  },
  {
    id: 3,
    name: 'DWS web-store',
    description: 'A more advanced React web application that allows companies to buy and sell products.',
    team: ['A.S.', 'L.D.M', 'J.V.D'],
    link: 'https://dws.qwict.com',
    github: 'https://github.com',
    image: 'QwictImage.png',
    lastUpdated: '2023-05-01',
  },
  {
    id: 4,
    name: 'DWS IMA',
    description: 'An inventory management application written in Java. This applications supports the DWS web-store and plugs into the same database. This makes it a very storng suite of applications',
    longDescription: '',
    team: ['A.S.', 'L.D.M', 'J.V.D'],
    link: 'https://dws.qwict.com',
    github: 'https://github.com',
    image: 'QwictVertical.png',
    lastUpdated: '2023-05-01',
  },
  {
    id: 5,
    name: 'DWS IMA',
    description: 'An inventory management application written in Java. This applications supports the DWS web-store and plugs into the same database. This makes it a very storng suite of applications',
    longDescription: '',
    team: ['A.S.', 'L.D.M', 'J.V.D'],
    link: 'https://dws.qwict.com',
    github: 'https://github.com',
    image: 'QwictShort.png',
    lastUpdated: '2023-05-01',
  },
  {
    id: 6,
    name: 'Nextcloud implementation',
    description: 'Qwict takes data security and privacy very serious, that is why we have implemented our own Nextcloud server. This allows us to store and share data with our clients in a secure way.',
    longDescription: '',
    team: ['J.V.D'],
    link: 'https://cloud.qwict.com',
    github: '',
    image: 'NextcloudImage.png',
    lastUpdated: '2023-05-01',
  },
];

export default projects;